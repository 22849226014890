import '../styles/index.scss';
import $ from 'jquery';

$( document ).ready(function() {
    setTimeout(() => {
        $('body').removeClass('preload');
    }, 200);
    $('.header__hamburger').on('click', function () {
        $(this).toggleClass('js-active');
        $('body').toggleClass('js-nav');
        $('.header').toggleClass('js-nav');
        $('.nav-mobile').toggleClass('js-active');
    });

    $(".menu-item-has-children").hover(function(){
        $(this).addClass('js-active');
        $(this).children('ul').slideDown('fast');
    }, function () {
        $(this).removeClass('js-active');
        $('ul', this).slideUp('fast');
    });

    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name= ' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - 50
                }, 500);
                return false;
            }
        }
    });
    function elementPosition() {
        var element = $('.js-paddingLeft');
        var container = $('.container').offset();
        var containerOffset = container.left + 25;
        $(element).css('padding-left', Math.round(containerOffset));
    }
    
    $(window).resize(elementPosition);
    elementPosition();

    function logoWidth() {
        var logo = $('.header__logo img');
        var navbar = $('.intro__nav');
        var maxLogoWidth = (navbar.width() - 50);
        
        if(maxLogoWidth > 334) {
            logo.css('max-width', 334);
        } else {
            logo.css('max-width', maxLogoWidth);
        }
        
    }
    $(window).resize(logoWidth);
    logoWidth();

    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
        if (scroll >= 50) {
            $('.header').addClass("js-scroll");
        } else {
            $('.header').removeClass("js-scroll");
        }
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
});